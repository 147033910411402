import AdminRecursosCostumizados from 'core/http/service/admin/AdminRecursosCostumizados.service';
import { PaginaIntermediariaService } from 'core/http/service/PaginaIntermediaria.service';
import { FilterRecursosCostumizados } from 'enums/FilterRecursosCostumizados';
import PaginaIntermediaria from 'models/admin/AdminPaginaIntermediaria';
import { ItemRecursosCostumizado } from 'models/recursos-costumizados/ItemRecursosCostumizado';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { RecursosContext } from './RecursosContext';
import { SelectOptionType } from 'components/Select';
import { ItemRecursosCostumizadoPublic } from 'models/recursos-costumizados/ItemRecursosCostumizadoPublic';
import path from 'path';
import { CertificacoesService } from 'core/http/service/Certificacoes.service';
import { CertificacoesModel } from 'models/certificacoes/CertificacoesModel';
import { ToastError } from 'components/Toaster';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { validateItemExclusivoManualmente } from 'helpers/Functions';
import UsuarioDTO from 'models/UsuarioDTO';
import keycloak from 'core/config/keycloak';
import { RoleEnum } from 'enums/RoleEnum';
import { KeycloakLoginOptions } from 'keycloak-js';

export interface IRecursosProvider {}

const initialValuesCertificacao = {
    codigo: -1,
    nome: '',
    textoSolicitacao: '',
    precisaSolicitar: true,
    link: '',
    // Add other initial values as needed
};
const RecursosProvider = ({ children }: React.PropsWithChildren<IRecursosProvider>) => {
    //Hooks
    const location = useLocation();

    const history = useHistory();
    // Services
    const paginaIntermediariaService = new PaginaIntermediariaService();
    const recursosCostumizadosService = new AdminRecursosCostumizados();
    const certificacaoService = new CertificacoesService();

    //States
    const [loading, setLoading] = useState(true);
    const [itensResumidos, setItensResumidos] = useState<ItemRecursosCostumizadoPublic[]>([]);
    const [item, setItem] = useState<ItemRecursosCostumizado | null>(null);
    const [paginaIntermediaria, setPaginaIntermediaria] = useState<PaginaIntermediaria | null>(null);
    const [filterSelected, setFilterSelected] = useState<SelectOptionType>({ label: 'Todos', value: FilterRecursosCostumizados.TODOS });
    const [certificacao, setCertificacao] = useState<CertificacoesModel>(initialValuesCertificacao as CertificacoesModel);

    const perfilReducer: PerfilUsuario | null = useAppSelector(state => state.profileReducer.userProfile);
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);

    const getIdByLink = async (): Promise<number> => {
        const link: string = location.pathname.split('/')[2];

        const { data, status } = await paginaIntermediariaService.buscarPaginaPorLink(link);
        const _pagina = new PaginaIntermediaria().fromJSON(data);
        setPaginaIntermediaria(_pagina);
        return _pagina.codigo;
    };

    const verifyAcesso = (): Boolean => {
        const link: string = location.pathname.split('/')[3];

        if (link === 'dialogos-socioemocionais-duplo-foco') {
            if (keycloak.authenticated && usuarioLogado.roles.includes(RoleEnum.Administrador)) {
                return true;
            }

            const validate = validateItemExclusivoManualmente(perfilReducer);

            if (validate) {
                return true;
            }
        }

        return false;
    };

    const fetchPage = async (status: FilterRecursosCostumizados | string = FilterRecursosCostumizados.TODOS) => {
        const codigo = await getIdByLink();

        if (codigo !== -1) {
            const { data } = await recursosCostumizadosService.listByPaginaOnlySimple(codigo);

            const itens: ItemRecursosCostumizadoPublic[] = Array.from(data).map((item: any) => {
              
                
                return new ItemRecursosCostumizadoPublic().fromJSON(item);
            });

            setItensResumidos(itens);
            setLoading(false);
        }
    };

    const fetchEtapasAndConteudos = async () => {
        const link: string = location.pathname.split('/')[3];

        if (link !== undefined) {

            const codigo = itensResumidos.find(item => item.url === `/${link}`)?.codigo;

            if (codigo !== undefined) {
                const { data, status } = await recursosCostumizadosService.listByEtapasAndConteudosResumidos(codigo ?? -1);
                const item = new ItemRecursosCostumizado().fromJSON(data);

                if (!item.possuiAcesso) {
                    if (!keycloak.authenticated && link === 'dialogos-socioemocionais-duplo-foco') {
                        const options: KeycloakLoginOptions = {
                            redirectUri:
                                window.location.origin + '/login?' + '/recursos/dialogos-socioemocionais/dialogos-socioemocionais-duplo-foco',
                        };

                        const _url = keycloak.createRegisterUrl(options);

                        window.location.assign(_url);
                        return;
                    }
                    const verificando = verifyAcesso();

                    if (!verificando) {
                        const link: string[] = location.pathname.split('/');
                        const url = `/${link[1]}/${link[2]}`;
                        history.push(url);

                        return;
                    }
                }

                const certificacao = await certificacaoService.listarById(data.codigoCertificacao);
                setCertificacao(certificacao.data);
                setItem(item);
            }
        }
    };

    const updateWithStatus = async (status: FilterRecursosCostumizados | string) => {
        await fetchPage(status), FilterSelecionado(status);
    };

    const FilterSelecionado = (status: FilterRecursosCostumizados | string) => {
        switch (status) {
            case FilterRecursosCostumizados.TODOS:
                return setFilterSelected({ label: 'Todos', value: FilterRecursosCostumizados.TODOS });
            case FilterRecursosCostumizados.INICIADOS:
                return setFilterSelected({ label: 'Iniciados', value: FilterRecursosCostumizados.INICIADOS });
            case FilterRecursosCostumizados.FINALIZADOS:
                return setFilterSelected({ label: 'Finalizados', value: FilterRecursosCostumizados.FINALIZADOS });
            case FilterRecursosCostumizados.NAO_INICIADOS:
                return setFilterSelected({ label: 'Não iniciados', value: FilterRecursosCostumizados.NAO_INICIADOS });
        }
    };

    useEffect(() => {
        if (item && item?.etapas.length > 0) {
            setLoading(false);
        }
    }, [item]);
    useEffect(() => {
        fetchPage();
    }, [location.pathname]);

    useEffect(() => {
        fetchEtapasAndConteudos();
    }, [itensResumidos, location.pathname]);

    const context: RecursosContext.IContext = {
        loading,
        updateWithStatus,
        itemRecurso: item,
        itensRecursoResumido: itensResumidos,
        filterSelected,
        pagina: paginaIntermediaria,
        certificacao: certificacao,
    };

    return <RecursosContext.Context.Provider value={context}>{children}</RecursosContext.Context.Provider>;
};
export default RecursosProvider;
