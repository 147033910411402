import Loading from 'components/Loading';
import Row from 'components/Row';
import { ToastSuccess, ToastError } from 'components/Toaster';
import { RedSolidButton, PrimaryButton, BlueSolidButton } from 'components/UI/Buttons';
import AdminGestaoPaginaService from 'core/http/service/admin/AdminGestaoPagina.service';
import AdminRecursosCostumizados from 'core/http/service/admin/AdminRecursosCostumizados.service';
import { PaginaIntermediariaService } from 'core/http/service/PaginaIntermediaria.service';
import { StringHelp } from 'core/util/StringHelp';
import Colors from 'enums/Colors';
import { RecursosCostumizadosEnum } from 'enums/RecursosCostumizados';
import AdminPaginaIntermediariaResumida from 'models/admin/AdminPaginaIntermediariaResumida';
import { KcAdminGroups } from 'models/kc/KcUserInfo';
import { PaginaIntermediariaTipoEnum } from 'models/paginas/PaginaIntermediariaTipo';
import { EtapaItemRecursosCostumizado, ConteudoOrderEtapa } from 'models/recursos-costumizados/EtapaItemRecursosCostumizado';
import { ItemRecursosCostumizado } from 'models/recursos-costumizados/ItemRecursosCostumizado';
import { ResumoRecursoCustomizado } from 'models/recursos-costumizados/ResumoRecursoCustomizado';
import hash from 'object-hash';
import AllowedGroupsValidator from 'pages/admin/components/allowedGroupsValidator/AllowedGroupsValidator';
import AdminBreadCrumb from 'pages/admin/components/breadcrumb/AdminBreadCrumb';
import { AdminGestaoPaginasMode } from 'pages/gestao_paginas/AdminGestaoPaginasContextWrapper';
import { AdminGestaoPaginasContext } from 'pages/gestao_paginas/context/AdminGestaoPaginasContext';
import { initialBreadCrumb } from 'pages/gestao_paginas/context/AdminGestaoPaginasProvider';
import AdminImageUploader from 'pages/gestao_paginas/section-form/imageUploader/AdminImageUploader';
import React, { useState, useEffect, useContext } from 'react';
import { FormControl, Form } from 'react-bootstrap';
import Switch from 'react-bootstrap/esm/Switch';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { MdDeleteForever } from 'react-icons/md';
import { useParams, useHistory } from 'react-router-dom';

import arrowIcon from '../../../../images/admin/arrow-r.svg';
import { AdminGestaoPaginasRecursosDropzone, AdminGestaoPaginasRecursosDragDrop } from '../components/dragdrop/DragDrop';
import ModalExclusao from '../components/modal/ModalExclusao';
import Styled from './AdminGestaoPaginaItemRecurso.styled';

import Select, { SelectOptionType } from 'components/Select';
import { Radio, Select as SelectedAnt, Space } from 'antd';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import { getOptionFromValue, tipoRedirecionamentoOptions } from 'pages/gestao_paginas/section-form/forms/tipoRedirecionamento';
import { CertificacoesModel } from 'models/certificacoes/CertificacoesModel';
import { CertificacoesService } from 'core/http/service/Certificacoes.service';
import useFetch from 'hooks/useFetch';
import { CategoriaPEService } from 'core/http/service/acessosExclusivos/CategoriaPE.service';
interface IItemRecurso {
    mode: AdminGestaoPaginasMode;
}

type CertificateOptionType = {
    value: number;
    label: string;
};

export type CategoriaPE = {
    id: number;
    nome: string;
};

const AdminGestaoDePaginaItemRecurso = ({ mode }: IItemRecurso) => {
    const history = useHistory();
    const params: any = useParams();
    const [codigoItem, setCodigoItem] = useState<number>(-1);
    const [idEtapa, setIdEtapa] = useState<number>(0);
    const [namePage, setNamePage] = useState<string>('');
    const [tipoRedirecionamento, setTipoRedirecionamento] = useState<TipoRedirecionamento>(TipoRedirecionamento.LOGIN);
    const [certificacao, setCertificacao] = useState<CertificateOptionType>({ value: -1, label: 'Nenhum' });
    const [certificacoesList, setCertificacoesList] = useState<any[]>([]);
    const [url, setUrl] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [descriptionExtra, setDescriptionExtra] = useState<string>('');
    const [cover, setCover] = useState<string | null>(null);
    const [codigoPage, setCodigoPage] = useState<number | null>(-1);
    const [linkExterno, setLinkExterno] = useState<boolean>(false);
    const [conteudoSequenciais, setConteudoSequenciais] = useState<boolean>(false);
    const [etapasSequenciais, setEtapasSequenciais] = useState<boolean>(false);
    const [itemOculto, setItemOculto] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState(false);
    const [etapasResumidas, setEtapasResumidas] = useState<ResumoRecursoCustomizado[] | null>(null);
    const [typeModeEdit, setTypeModeEdit] = useState<boolean>(mode === 'NEW' ? false : true);
    const [excluxed, setExcluxed] = useState<RecursosCostumizadosEnum>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [quantidade, setQuantidade] = useState<number>(-1);
    const [categorias, setCategorias] = useState<CategoriaPE[]>([]);
    const [categoriaSelected, setCategoriaSelected] = useState<CategoriaPE[]>([]);

    const [loadingOrdem, setLoadingOrdem] = useState<boolean>(false);

    const MAX_CHAR_CONTENT = 500;
    const MAX_CHAR_CONTENT2 = 300;
    const MAX_CHAR_CONTENT_URL = 200;
    const MAX_CHAR_CONTENT_NAME = 100;

    const serviceRecursosCostumizados = new AdminRecursosCostumizados();
    const serviceCategoriaPE = new CategoriaPEService();

    const serviceCertificacao = new CertificacoesService();

    const { breadCrumb, setBreadCrumb, currentPage, selectPage } = useContext(AdminGestaoPaginasContext.Context);

    const tipoRedirecionamentoSelectOptions: SelectOptionType[] = [
        {
            label: 'Login',
            value: TipoRedirecionamento.LOGIN,
        },
        {
            label: 'Cadastro',
            value: TipoRedirecionamento.CADASTRO,
        },
    ];

    const breadCrumbInitial = (name?: string) => {
        setBreadCrumb([
            { route: 'gestao-paginas', label: 'Gestão de páginas' },
            { route: 'gestao-paginas/editar-recurso/' + currentPage?.codigo, label: 'Editar Recurso' },
            { route: 'gestao-paginas/editar-item-recurso', label: name ?? 'Item' },
        ]);
    };

    const setSequencial = async (value: boolean) => {
        const { data } = await serviceRecursosCostumizados.jaConcluido(codigoItem);

        if (data) {
            ToastError('Esse item já foi concluído por um usuário, não é possível alterá-lo.');
            return;
        }
        setConteudoSequenciais(value);
    };

    const setEtapaSequencial = async (value: boolean) => {
        const { data } = await serviceRecursosCostumizados.jaConcluido(codigoItem);

        if (data) {
            ToastError('Esse item já foi concluído por um usuário, não é possível alterá-lo.');
            return;
        }
        setEtapasSequenciais(value);
    };

    const fetchEtapasResumidas = async (itemId: number) => {
        const { data } = await serviceRecursosCostumizados.buscarListaEtapaResumida(itemId);

        const etapasResumidos = Array.from(data).map((i: any) => new ResumoRecursoCustomizado().fromJSON(i));

        setEtapasResumidas(etapasResumidos);
    };

    const scrollToTop = () => {
        document.documentElement.scrollIntoView({
            behavior: 'smooth',
        });
    };

    const fetchItem = async (itemId: number) => {
        const { data } = await serviceRecursosCostumizados.itemGetById(itemId);
        const response = await serviceCertificacao.listarAll();
        const pagedata = new ItemRecursosCostumizado().fromJSON(data);
        
        const listCertificado = [{ codigo: -1, nome: 'Nenhum' }, ...response.data];
        
        const certificacaoSelecionada =
        pagedata.codigoCertificacao !== -1 && pagedata.codigoCertificacao !== null
        ? listCertificado.find(c => c.codigo === pagedata.codigoCertificacao)
        : { codigo: -1, nome: 'Nenhum' };
        
        const categoriaSelecionada = 
        pagedata.codigosCategoriaPaginaExclusiva !== null && pagedata.codigosCategoriaPaginaExclusiva.length > 0
        ? pagedata.codigosCategoriaPaginaExclusiva.map((d) => ({id: d.id ?? -1, nome: d.nome})) : []
        
       
        setCertificacoesList(listCertificado);
        setCategoriaSelected(categoriaSelecionada);
        setCodigoItem(pagedata.codigo ?? -1);
        setNamePage(pagedata.nome);
        setCover(pagedata.anexoBase64);
        setUrl(pagedata.url);
        setLinkExterno(pagedata.linkExterno);
        setConteudoSequenciais(pagedata.conteudosSequenciais);
        setEtapaSequencial(pagedata.etapasSequenciais);
        setDescription(pagedata.descricao);
        setDescriptionExtra(pagedata.descricaoExtra);
        setTipoRedirecionamento(pagedata.tipoRedirecionamento);
        setCertificacao({ value: certificacaoSelecionada?.codigo ?? -1, label: certificacaoSelecionada?.nome ?? 'Nenhum' });
        breadCrumbInitial(pagedata.nome);
        fetchEtapasResumidas(itemId);
        setItemOculto(pagedata.oculto);
        setQuantidade(pagedata.ordem ?? 0);
        setLoading(false);
    };

    const isValidInputs = (): boolean => {
        if (!cover) return false;
        return url.trim().length > 0 && namePage.trim().length > 0 && description.trim().length > 0;
    };

    const submitItem = async () => {
        if (!isValidInputs()) {
            ToastError('Dados da página inválidos');

            return;
        }
        setSubmitting(true);

        const sanitizeUrl = StringHelp.removeAccents(url)
            .replace(/\s/g, '-')
            .replace(/[^a-z0-9-]/gi, '')
            .toLowerCase();

        const currentLink: string = (currentPage?.link ?? '').replace(/[^a-z0-9-]/gi, '');

        let isExistent = false;

        if (currentLink != sanitizeUrl) {
            const { data: value } = await serviceRecursosCostumizados.verificarUriExistent('/' + sanitizeUrl, codigoItem, codigoPage, 'item');
            isExistent = value;
        }

        if (!isExistent) {
            const valueCertificado = certificacao.value === -1 ? null : certificacao.value;

            const payload = new ItemRecursosCostumizado().fromJSON({
                codigoPaginaIntermediaria: codigoPage,
                nome: namePage,
                url: '/' + sanitizeUrl.replace(/^\//g, ''),
                descricao: description,
                descricaoExtra: descriptionExtra,
                linkExterno: linkExterno,
                conteudosSequenciais: conteudoSequenciais,
                etapasSequenciais: etapasSequenciais,
                tipoRedirecionamento: tipoRedirecionamento,
                codigoCertificacao: valueCertificado,
                codigosCategoriaPaginaExclusiva: categoriaSelected,
                anexoBase64: cover,
                ordem: quantidade,
            });
            
            try {
                if (mode === 'NEW' && codigoItem === -1) {
                    const { status, data } = await serviceRecursosCostumizados.novoItem(payload);

                    if (status < 300) {
                        ToastSuccess('Item salvo');
                        setCodigoItem(data.codigo);
                        setUrl(data.url);
                        fetchItem(data.codigo);
                        breadCrumbInitial(data.nome);
                        setTypeModeEdit(true);
                    }
                } else {
                    const { status, data } = await serviceRecursosCostumizados.atualizarItem(codigoItem, payload);

                    if (status < 300) {
                        ToastSuccess('Item salvo');
                        setUrl(data.url);
                        breadCrumbInitial(data.nome);
                    }
                }
            } catch (err) {}
        } else ToastError('Já existe uma página para a URL informada');
        setSubmitting(false);
        scrollToTop();
    };

    const setupOrder = async (etapa: ResumoRecursoCustomizado, neworder: number) => {
        setLoadingOrdem(true);

        const { codigo } = etapa;
        const dados = new ConteudoOrderEtapa();
        dados.codigo = codigo;
        dados.codigoItem = codigoItem;
        dados.ordem = neworder;

        const sequencial = await serviceRecursosCostumizados.getSenquencial(codigoItem);

        if (sequencial.data) {
            ToastError('Não é possível ordenar conteúdos sequênciais');
            setLoadingOrdem(false);
            return;
        }

        const { data, status } = await serviceRecursosCostumizados.uptadeOrdemEtapa(dados);

        if (status == 200) {
            const resumoRecurso = Array.from(data).map((s: any) => new ResumoRecursoCustomizado().fromJSON(s));

            const _resumos: ResumoRecursoCustomizado[] = [];
            for (const resumo of resumoRecurso) {
                const dados = new ResumoRecursoCustomizado();
                dados.codigo = resumo.codigo;
                dados.nome = resumo.nome;
                dados.oculto = resumo.oculto;
                dados.ordem = resumo.ordem;
                _resumos.push(resumo);
            }
            setEtapasResumidas(_resumos);
            ToastSuccess('Ordem alterada com sucesso!');
        } else {
            ToastError('Houve um erro ao tentar ordenar os conteúdos.');
        }

        setLoadingOrdem(false);
    };

    const handleOcultar = async (id: number, type: RecursosCostumizadosEnum) => {
        if (type === RecursosCostumizadosEnum.ITEM) {
            const response = await serviceRecursosCostumizados.ocultarItem(id);

            if (response.status === 200) {
                ToastSuccess('Item ocultado.');
                fetchInitial();
            }
        } else {
            const response = await serviceRecursosCostumizados.ocultarEtapa(id);

            if (response.status === 200) {
                ToastSuccess('Etapa ocultado.');
                fetchInitial();
            }
        }
    };

    const handleExibir = async (id: number, type: RecursosCostumizadosEnum) => {
        if (type === RecursosCostumizadosEnum.ITEM) {
            const response = await serviceRecursosCostumizados.exibirItem(id);

            if (response.status === 200) {
                ToastSuccess('Item visivel.');

                fetchInitial();
            }
        } else {
            const response = await serviceRecursosCostumizados.exibirEtapa(id);

            if (response.status === 200) {
                ToastSuccess('Etapa visivel.');
                fetchInitial();
            }
        }
    };

    const handleDelete = async () => {
        if (excluxed === RecursosCostumizadosEnum.ITEM) {
            const response = await serviceRecursosCostumizados.deleteItem(codigoItem);

            if (response.status === 200) {
                ToastSuccess('Item excluido com sucesso!');

                history.push('/admin/gestao-paginas/editar-recurso/' + codigoPage);
            } else {
                ToastError('Houve algum problema ao tentar excluir o conteúdo.');
            }
        } else {
            const response = await serviceRecursosCostumizados.deleteEtapa(idEtapa);

            if (response.status === 200) {
                ToastSuccess('Etapa excluida com sucesso!');

                const novaLista = etapasResumidas?.filter(e => e.codigo !== idEtapa);
                setEtapasResumidas(novaLista ?? []);
            } else {
                ToastError('Houve algum problema ao tentar excluir o conteúdo.');
            }
        }

        setShowModal(false);
    };

    const fetchInitial = () => {
        const id = String(params?.id);
        const quantidade = String(params?.quantidade);
        setQuantidade(parseInt(quantidade));

        if (currentPage) {
            setCodigoPage(currentPage?.codigo);
        }

        if (mode == 'EDIT') {
            fetchItem(parseInt(id));
            setLoading(true);

            return;
        }

        breadCrumbInitial();
    };

    const validateOrder = () => {
        let newOrder = 0;

        for (const etapas of etapasResumidas ?? []) {
            if (etapas.ordem > newOrder) {
                newOrder = etapas.ordem;
            }
        }

        return newOrder + 1;
    };

    useFetch(async () => {
        const response = await serviceCategoriaPE.listAll();

        const { data, status } = response;

        if (status === 200) {
            const newList = data.map((dados: CategoriaPE) => ({ id: dados.id, nome: dados.nome }));
            setCategorias(newList);
        }
    }, []);

    useFetch(async () => {
        if (mode === 'NEW') {
            const response = await serviceCertificacao.listarAll();
            const listCertificado = [{ codigo: -1, nome: 'Nenhum' }, ...response.data];
            setCertificacoesList(listCertificado);
        }
    }, []);

    const handleChange = (selectedIds: string[]) => {
        // Converte os IDs selecionados para números e filtra valores inválidos
        const selectedCategorias = categorias.filter(categoria => selectedIds.includes(String(categoria.id)));
        setCategoriaSelected(selectedCategorias);
    };

    useEffect(() => {
        fetchInitial();
    }, [categorias]);

    useEffect(() => {
        fetchInitial();
    }, []);

    return (
        <Styled.Container>
            {isLoading ? (
                <Loading />
            ) : (
                <React.Fragment>
                    <ModalExclusao showModal={showModal} handleHiden={setShowModal} handleExcluxed={handleDelete} />
                    <AdminBreadCrumb crumbs={breadCrumb} />
                    <br />
                    <Styled.DividorRowBettween>
                        <Styled.Title>{'Dados básicos do item'}</Styled.Title>
                    </Styled.DividorRowBettween>
                    <br />
                    <Styled.FormContainer>
                        <Styled.Divisor style={{ justifyContent: 'flex-end', margin: '0px', position: 'relative', top: '-15px' }}>
                            <Styled.Divisor width="350px">
                                <Styled.Label>Nome do item</Styled.Label>
                                <Styled.Input
                                    value={namePage}
                                    onChange={(evn: any) => setNamePage(evn.target.value)}
                                    className={'form-control'}
                                    maxLength={MAX_CHAR_CONTENT_NAME}
                                />
                                <Styled.CountLetter danger={namePage.length >= MAX_CHAR_CONTENT_NAME}>
                                    {namePage.length}/{MAX_CHAR_CONTENT_NAME}
                                </Styled.CountLetter>
                            </Styled.Divisor>
                            <Styled.Divisor width="350px">
                                <Styled.Label>URL</Styled.Label>
                                <Styled.Input
                                    value={url}
                                    onChange={(evn: any) =>
                                        setUrl('/' + StringHelp.alnum(StringHelp.toSize(evn.target.value.toLowerCase().trim(), 200)))
                                    }
                                    className={'form-control'}
                                    maxLength={MAX_CHAR_CONTENT_URL}
                                />
                                <Styled.CountLetter danger={url.length >= MAX_CHAR_CONTENT_URL}>
                                    {url.length}/{MAX_CHAR_CONTENT_URL}
                                </Styled.CountLetter>
                            </Styled.Divisor>
                            <Styled.Divisor width="350px" style={{ zIndex: 101 }}>
                                <Styled.Label>Tipo de redirecionamento</Styled.Label>
                                <Select
                                    isSmall
                                    value={getOptionFromValue(tipoRedirecionamento)}
                                    itens={tipoRedirecionamentoSelectOptions}
                                    onSelected={o => setTipoRedirecionamento(o.value as TipoRedirecionamento)}
                                />
                            </Styled.Divisor>

                            <Styled.Divisor width="350px" style={{ zIndex: 100 }}>
                                <Styled.Label>Tipo de certificação</Styled.Label>
                                <Select
                                    width="250px"
                                    isSmall={true}
                                    value={
                                        certificacao != null
                                            ? { value: certificacao.value, label: certificacao.label }
                                            : { value: -1, label: 'Nenhum' }
                                    }
                                    itens={certificacoesList.map(certificacao => ({
                                        value: certificacao.codigo,
                                        label: certificacao.nome,
                                    }))}
                                    onSelected={o => setCertificacao({ value: parseInt(o.value.toString()), label: o.label })}
                                />
                            </Styled.Divisor>
                            <Styled.Divisor width="350px" style={{ zIndex: 99 }}>
                                <Styled.Label>Categorias</Styled.Label>
                                <SelectedAnt
                                style={{minHeight: 40}}
                                    mode="multiple"
                                    value={categoriaSelected.map(categoria => String(categoria.id))} // Converte para string
                                    options={categorias.map(categoria => ({
                                        value: String(categoria.id),
                                        label: categoria.nome,
                                    }))}
                                    onChange={handleChange}
                                    allowClear 
                                />
                            </Styled.Divisor>
                            <Styled.Divisor style={{ flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                <Row justify="end" style={{ marginTop: '10px', width: '100%', justifyContent: 'space-between' }}>
                                    <Styled.CkeckContainer>
                                        <span style={{ marginRight: '10px' }}>Conteúdos sequênciais</span>
                                        <Form>
                                            <Form.Check
                                                type="switch"
                                                id={'item'}
                                                checked={conteudoSequenciais ?? false}
                                                onClick={() => setSequencial(!conteudoSequenciais)}
                                            />
                                        </Form>
                                    </Styled.CkeckContainer>
                                    <Styled.CkeckContainer>
                                        <span style={{ marginRight: '10px' }}>Etapas sequênciais</span>
                                        <Form>
                                            <Form.Check
                                                type="switch"
                                                id={'etapa'}
                                                checked={etapasSequenciais ?? false}
                                                onClick={() => setEtapaSequencial(!etapasSequenciais)}
                                            />
                                        </Form>
                                    </Styled.CkeckContainer>
                                </Row>
                            </Styled.Divisor>
                        </Styled.Divisor>

                        <Styled.Divisor>
                            <AdminImageUploader
                                onSelect={() => {}}
                                setBase64={b => setCover(b)}
                                size={'sm'}
                                title={'Imagem da capa'}
                                preImageB64={cover}
                                recommendedDimension={{ w: 200, h: 130 }}
                            />
                        </Styled.Divisor>
                    </Styled.FormContainer>
                    <Styled.FormContainer>
                        <Styled.Divisor width="100%">
                            <Styled.Label>Descrição do item</Styled.Label>
                            <Styled.Input
                                value={description}
                                onChange={(evn: any) => setDescription(evn.target.value)}
                                rows={5}
                                as="textarea"
                                className={'form-control'}
                                maxLength={MAX_CHAR_CONTENT}
                            />
                            <Styled.CountLetter danger={description.length >= MAX_CHAR_CONTENT}>
                                {description.length}/{MAX_CHAR_CONTENT}
                            </Styled.CountLetter>
                            <Styled.Label>Descrição extra</Styled.Label>
                            <Styled.Input
                                value={descriptionExtra}
                                onChange={(evn: any) => setDescriptionExtra(evn.target.value)}
                                rows={5}
                                as="textarea"
                                className={'form-control'}
                                maxLength={MAX_CHAR_CONTENT2}
                            />
                            <Styled.CountLetter danger={descriptionExtra?.length >= MAX_CHAR_CONTENT2}>
                                {descriptionExtra?.length}/{MAX_CHAR_CONTENT2}
                            </Styled.CountLetter>

                            <Row justify="end" style={{ marginTop: 20, justifyContent: 'space-between' }}>
                                {typeModeEdit && (
                                    <>
                                        <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                            <Styled.DivAround style={{ justifyContent: 'space-between', width: '200px' }}>
                                                <Row>
                                                    <RedSolidButton
                                                        onClick={() => {
                                                            setExcluxed(RecursosCostumizadosEnum.ITEM);
                                                            setShowModal(true);
                                                        }}
                                                    >
                                                        Deletar
                                                    </RedSolidButton>
                                                </Row>
                                            </Styled.DivAround>
                                        </AllowedGroupsValidator>
                                        <Styled.CkeckContainer>
                                            <Row>
                                                <span style={{ marginRight: '10px', fontSize: '16px', fontWeight: 600 }}>Ocultar</span>
                                                <Form>
                                                    <Form.Check
                                                        type="switch"
                                                        id={'item' + codigoItem}
                                                        checked={itemOculto}
                                                        onClick={() => {
                                                            !itemOculto
                                                                ? handleOcultar(codigoItem, RecursosCostumizadosEnum.ITEM)
                                                                : handleExibir(codigoItem, RecursosCostumizadosEnum.ITEM);
                                                        }}
                                                    />
                                                </Form>
                                            </Row>
                                        </Styled.CkeckContainer>
                                    </>
                                )}
                                <Row justify="end">
                                    <BlueSolidButton disabled={submitting} onClick={submitItem}>
                                        {submitting ? 'Salvando...' : 'Salvar página'}
                                    </BlueSolidButton>
                                </Row>
                            </Row>
                        </Styled.Divisor>
                    </Styled.FormContainer>
                    <Styled.BottomContainer>
                        <hr style={{ margin: ' 30px 0' }} />
                        {codigoItem !== -1 && (
                            <>
                                <Row justify="space-between" style={{ maxWidth: '1100px', alignItems: 'center', marginBottom: '30px' }}>
                                    <Styled.Title>Etapas do item</Styled.Title>
                                </Row>
                                <div>
                                    {loadingOrdem && (
                                        <div style={{ position: 'absolute', left: '400px' }}>
                                            <Loading />
                                        </div>
                                    )}
                                    <Styled.ListItens key={hash(etapasResumidas)} loading={loadingOrdem}>
                                        <DndProvider backend={HTML5Backend}>
                                            {etapasResumidas
                                                ?.sort((s1, s2) => (s1?.ordem ?? 0) - (s2?.ordem ?? 0))
                                                .map((d, index) => (
                                                    <React.Fragment key={index}>
                                                        <AdminGestaoPaginasRecursosDropzone thisOrder={d.ordem ?? -1} onDropItem={setupOrder} />
                                                        <AdminGestaoPaginasRecursosDragDrop secao={d}>
                                                            <Styled.RedirectButton>
                                                                <Styled.DivSpanCard
                                                                    onClick={() =>
                                                                        history.push(
                                                                            '/admin/gestao-paginas/editar-etapa-item/' +
                                                                                namePage +
                                                                                '/' +
                                                                                codigoItem +
                                                                                '/' +
                                                                                d.codigo
                                                                        )
                                                                    }
                                                                >
                                                                    <span>{d.nome}</span>
                                                                </Styled.DivSpanCard>
                                                                <Styled.DivAround>
                                                                    <Styled.CkeckContainer>
                                                                        <Row>
                                                                            <span style={{ marginRight: '10px', fontSize: '14px' }}>
                                                                                Ocultar
                                                                            </span>
                                                                            <Form>
                                                                                <Form.Check
                                                                                    type="switch"
                                                                                    id={'conteudo' + d.codigo}
                                                                                    checked={d.oculto}
                                                                                    onClick={() => {
                                                                                        !d.oculto
                                                                                            ? handleOcultar(
                                                                                                  d.codigo,
                                                                                                  RecursosCostumizadosEnum.ETAPA
                                                                                              )
                                                                                            : handleExibir(
                                                                                                  d.codigo,
                                                                                                  RecursosCostumizadosEnum.ETAPA
                                                                                              );
                                                                                    }}
                                                                                />
                                                                            </Form>
                                                                        </Row>
                                                                    </Styled.CkeckContainer>
                                                                    <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                                                        <MdDeleteForever
                                                                            onClick={() => {
                                                                                setIdEtapa(d.codigo);
                                                                                setExcluxed(RecursosCostumizadosEnum.ETAPA);
                                                                                setShowModal(true);
                                                                            }}
                                                                            size={25}
                                                                            color={Colors.DeleteRed}
                                                                            cursor={'pointer'}
                                                                        />
                                                                    </AllowedGroupsValidator>
                                                                </Styled.DivAround>
                                                                <img src={arrowIcon} />
                                                            </Styled.RedirectButton>
                                                        </AdminGestaoPaginasRecursosDragDrop>
                                                        {index == etapasResumidas.length - 1 && (
                                                            <AdminGestaoPaginasRecursosDropzone
                                                                thisOrder={(d.ordem ?? 0) + 1}
                                                                onDropItem={setupOrder}
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                        </DndProvider>
                                    </Styled.ListItens>
                                </div>
                                <PrimaryButton
                                    onClick={() =>
                                        history.push(
                                            '/admin/gestao-paginas/nova-etapa-item/' + namePage + '/' + codigoItem + '/' + validateOrder()
                                        )
                                    }
                                >
                                    Adicionar nova etapa
                                </PrimaryButton>
                            </>
                        )}
                    </Styled.BottomContainer>
                </React.Fragment>
            )}
        </Styled.Container>
    );
};

export default AdminGestaoDePaginaItemRecurso;
